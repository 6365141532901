<template>
  <div class="container">
    <div class="content-box">
      <h1>News & Updates</h1>

      <ul class="list-reset">
        <li v-for="item in devlogList.items" :key="item.sys.id" class="news-item">
          <article>
            <h3>{{ item.fields.title }}</h3>
            <div>{{ item.sys.createdAt | date }}</div>
            <div class="news-item__short-text">{{ item.fields.shortText }}</div>
            <div class="news-read-more-link"><router-link :to="`/news/`+item.sys.id">Read more</router-link></div>
          </article>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'News',
  components: {
  },
  metaInfo () {
    return {
      title: 'Guilds & Blades - News',
      link: [
        { rel: 'canonical', href: 'https://guildsandblades.com/news' }
      ],
      meta: [{
        name: 'description',
        content: 'Guilds & Blades - Recent News'
      }]
    }
  },
  computed: {
    ...mapState('devlogList', ['devlogList', 'loading', 'exception'])
  },
  mounted () {
    this.fetchChangelogList()
  },
  methods: {
    ...mapActions('devlogList', [
      'fetchChangelogList'
    ])

  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.news {
  &-item {
    margin: $spacing1 0;
    padding: $spacing1;
    border: 1px solid transparentize($colorContent, 0.9);

    @media(min-width: $breakpointDesktopLg) {
      padding: $spacing2;
    }

    &__short-text {
      margin-top: 5px;
    }
  }

  &-read-more-link {
    margin-top: $spacing1;
  }
}

</style>
